<template>
    <div class="page page-public page-signup">
        <div class="public-wrap">
            <window-setting></window-setting>

            <div class="banner drag">
                <img src="https://media.oz4.cn/assets/img/sign-slider.jpg">
            </div>

            <div class="public-container">
                <div class="title">
                    注册账号
                </div>
                <Form :model="signupForm" ref="signupForm" :rules="signupFormValidate" :label-width="0">
                    <FormItem prop="mobile" >

                        <Input v-model="signupForm.mobile" placeholder="手机号码">
                            <icon-svg icon-class="icon-nickname" slot="prefix" ></icon-svg>
                        </Input>
                    </FormItem>

                    <FormItem class="code" prop="code">
                        <Input
                            v-model="signupForm.code"
                            :maxlength="4"
                            placeholder="验证码">
                            <icon-svg icon-class="icon-safety" slot="prefix" ></icon-svg>
                            <div slot="suffix" >
                                <div class="send-code" v-show="!this.countDown.timer" @click="sendCode()">获取验证码</div>
                                <div class="count-down" v-show="this.countDown.timer">{{this.countDown.count}}秒后失效</div>
                            </div>
                        </Input>
                    </FormItem>
                    <FormItem prop="password">
                        <Input
                            @keyup.enter.native="signup()"
                            v-model="signupForm.password"
                            type="text"
                            placeholder="登录密码"
                        >
                        <icon-svg icon-class="icon-privac_open" slot="prefix" ></icon-svg>
                        </Input>
                    </FormItem>
                    <FormItem class="reset">
                        <Button type="primary" long  :loading="loading" @click="signup()">立即注册</Button>
                    </FormItem>
                </Form>


                <div class="type">
                    <div class="pull-right">
                        <font>已有账号？</font>
                        <router-link class="item signin" to="signin">去登录</router-link>
                    </div>


                </div>

            </div>
        </div>
    </div>
</template>

<script>
import windowSetting from '@/components/comp-window-setting/window-setting';
export default {
  data() {
    return {
      signupForm: {
        mobile: '',
        code: '',
        password: '',
      },
      signupFormValidate: {
        mobile: [
          {
            required: true,
            message: '请输入手机号码！',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确的手机号码！',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入验证码！',
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 4,
            max: 4,
            message: '请输入正确的验证码！',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码！',
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 6,
            max: 32,
            message: '密码格式不正确！',
            trigger: 'blur',
          },
        ],

      },
      countDown: {
        show: true,
        count: '',
        timer: null,
      },

      loading: false,

    };
  },
  computed: {},
  components: {
    windowSetting,
  },
  created() {},
  mounted() {},
  methods: {
    signup() {
      this.$refs.signupForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = this.signupForm;
          params.source = 'push_desktop';
          this.$api.signup(params)
            .then((res) => {
              if (res.code >= 0 && res.data) {
                this.loading = false;
                this.$Message.success('注册成功，自动登录！');
                this.$util.setStorage('token', res.data.token);
                this.$store.commit('tokenToVuex');
                this.loading = false;
                this.$router.push({
                  name: 'index',
                });
                // this.signin({ mobile: params.mobile, password: params.password });
              } else {
                this.$Message.error(res.message);
                this.loading = false;
              }
            });
        }
      });
    },

    signin(params) {
      this.$api.signin(params).then((res) => {
        if (res.code >= 0 && res.data) {
          this.$Message.success('登录成功！');
          this.$util.setStorage('token', res.data.token);
          this.$store.commit('tokenToVuex');

          this.loading = false;
          this.$router.push({
            name: 'index',
          });
        }
      });
    },

    // 获取验证码
    sendCode() {
      if (!this.countDown.timer) {
        this.$refs.signupForm.validateField('mobile', (valid) => {
          if (!valid) {
            console.log('发送');

            this.$api.getSmsCode({
              mobile: this.signupForm.mobile,
            })
              .then((res) => {
                if (res.code >= 0 && res.data) {
                  this.$Message.success('发送成功');
                  this.sendCodeCountDown();
                } else {
                  this.$Message.error(res.message);
                }
              });
          }
        });
      }
    },

    sendCodeCountDown() {
      // 倒计时
      const TIME_COUNT = 60;
      this.countDown.count = TIME_COUNT;
      this.countDown.show = false;

      this.countDown.timer = setInterval(() => {
        if (this.countDown.count > 0 && this.countDown.count <= TIME_COUNT) {
          this.countDown.count--;
        } else {
          this.countDown.show = true;
          clearInterval(this.countDown.timer);
          this.countDown.timer = null;
        }
      }, 1000);
    },


  },
};
</script>
<style>
    @import url("./css/public.css");
</style>
